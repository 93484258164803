import { ReactElement } from 'react';
// @ts-ignore
import { ReactComponent as InstagramIcon } from '../../common/components/SVGIcon/assets/author/instagram.svg';
// @ts-ignore
import { ReactComponent as PhoneIcon } from '../../common/components/SVGIcon/assets/author/phone.svg';
// @ts-ignore
import { ReactComponent as SignalIcon } from '../../common/components/SVGIcon/assets/author/signal.svg';
// @ts-ignore
import { ReactComponent as ThreemaIcon } from '../../common/components/SVGIcon/assets/author/threema.svg';
// @ts-ignore
import { ReactComponent as WebsiteIcon } from '../../common/components/SVGIcon/assets/author/website.svg';
// @ts-ignore
import { ReactComponent as ArrowUpAndDownIcon } from '../../common/components/SVGIcon/assets/iconFont/arrow-up-and-down.svg';
// @ts-ignore
import { ReactComponent as Book } from '../../common/components/SVGIcon/assets/iconFont/book.svg';
// @ts-ignore
import { ReactComponent as Calculator } from '../../common/components/SVGIcon/assets/iconFont/calculator.svg';
import { ReactComponent as Cart } from '../../common/components/SVGIcon/assets/iconFont/cart.svg';
// @ts-ignore
import { ReactComponent as ChevronLeft } from '../../common/components/SVGIcon/assets/iconFont/chevron-left.svg';
// @ts-ignore
import { ReactComponent as ChevronRight } from '../../common/components/SVGIcon/assets/iconFont/chevron-right.svg';
// @ts-ignore
import { ReactComponent as Close } from '../../common/components/SVGIcon/assets/iconFont/close.svg';
// @ts-ignore
import { ReactComponent as Contact } from '../../common/components/SVGIcon/assets/iconFont/contact.svg';
// @ts-ignore
import { ReactComponent as Download } from '../../common/components/SVGIcon/assets/iconFont/download.svg';
// @ts-ignore
import { ReactComponent as FullFileSpace } from '../../common/components/SVGIcon/assets/iconFont/full_file_space.svg';
// @ts-ignore
import { ReactComponent as FullscreenIcon } from '../../common/components/SVGIcon/assets/iconFont/fullscreen.svg';
// @ts-ignore
import { ReactComponent as Info } from '../../common/components/SVGIcon/assets/iconFont/info.svg';
// @ts-ignore
import { ReactComponent as Menu } from '../../common/components/SVGIcon/assets/iconFont/menu.svg';
// @ts-ignore
import { ReactComponent as PlayButtonIcon } from '../../common/components/SVGIcon/assets/iconFont/play-button.svg';
// @ts-ignore
import { ReactComponent as QuoteIcon } from '../../common/components/SVGIcon/assets/iconFont/quote-icon.svg';
// @ts-ignore
import { ReactComponent as Search } from '../../common/components/SVGIcon/assets/iconFont/search.svg';
// @ts-ignore
import { ReactComponent as StarEmptyIcon } from '../../common/components/SVGIcon/assets/iconFont/star-empty.svg';
// @ts-ignore
import { ReactComponent as StarFullIcon } from '../../common/components/SVGIcon/assets/iconFont/star-full.svg';
// @ts-ignore
import { ReactComponent as Upload } from '../../common/components/SVGIcon/assets/iconFont/upload.svg';
// @ts-ignore
import { ReactComponent as UserActive } from '../../common/components/SVGIcon/assets/iconFont/user-active.svg';
// @ts-ignore
import { ReactComponent as User } from '../../common/components/SVGIcon/assets/iconFont/user.svg';
// @ts-ignore
import { ReactComponent as OfflineIcon } from '../../common/components/SVGIcon/assets/statusPage/offline.svg';
// @ts-ignore
import { ReactComponent as ArrowBackIcon } from '../../common/components/SVGIcon/assets/utilityBar/arrow_back.svg';
// @ts-ignore
import { ReactComponent as BookmarkActiveIcon } from '../../common/components/SVGIcon/assets/utilityBar/bookmark_active.svg';
// @ts-ignore
import { ReactComponent as BookmarkInactiveIcon } from '../../common/components/SVGIcon/assets/utilityBar/bookmark_inactive.svg';
// @ts-ignore
import { ReactComponent as CommentIcon } from '../../common/components/SVGIcon/assets/utilityBar/comment.svg';
// @ts-ignore
import { ReactComponent as FacebookIcon } from '../../common/components/SVGIcon/assets/utilityBar/facebook.svg';
// @ts-ignore
import { ReactComponent as GetPocketIcon } from '../../common/components/SVGIcon/assets/utilityBar/getpocket.svg';
// @ts-ignore
import { ReactComponent as GoogleNewsIcon } from '../../common/components/SVGIcon/assets/utilityBar/google-news.svg';
// @ts-ignore
import { ReactComponent as LinkedinIcon } from '../../common/components/SVGIcon/assets/utilityBar/linkedin.svg';
// @ts-ignore
import { ReactComponent as ListenIcon } from '../../common/components/SVGIcon/assets/utilityBar/listen.svg';
// @ts-ignore
import { ReactComponent as MailIcon } from '../../common/components/SVGIcon/assets/utilityBar/mail.svg';
// @ts-ignore
import { ReactComponent as MessengerIcon } from '../../common/components/SVGIcon/assets/utilityBar/messenger.svg';
// @ts-ignore
import { ReactComponent as PinterestIcon } from '../../common/components/SVGIcon/assets/utilityBar/pinterest.svg';
// @ts-ignore
import { ReactComponent as PrintIcon } from '../../common/components/SVGIcon/assets/utilityBar/print.svg';
// @ts-ignore
import { ReactComponent as ShareIcon } from '../../common/components/SVGIcon/assets/utilityBar/share.svg';
// @ts-ignore
import { ReactComponent as TwitterIcon } from '../../common/components/SVGIcon/assets/utilityBar/twitter.svg';
// @ts-ignore
import { ReactComponent as WhatsappIcon } from '../../common/components/SVGIcon/assets/utilityBar/whatsapp.svg';
// @ts-ignore
import { ReactComponent as XingIcon } from '../../common/components/SVGIcon/assets/utilityBar/xing.svg';
// @ts-ignore
import { ReactComponent as ListIcon } from '../../common/components/SVGIcon/assets/iconFont/list.svg';
// @ts-ignore
import { ReactComponent as ListIconActive } from '../../common/components/SVGIcon/assets/iconFont/list-active.svg';

export const SVG_ICONS_TYPE_BOOKMARK_ACTIVE = 'svg-icons/type/bookmark-active';
export const SVG_ICONS_TYPE_BOOKMARK_INACTIVE =
  'svg-icons/type/bookmark-inactive';
export const SVG_ICONS_TYPE_ARROW_BACK = 'svg-icons/type/arrow-back';
export const SVG_ICONS_TYPE_ARROW_UP_AND_DOWN =
  'svg-icons/type/arrow-up-and-down';
export const SVG_ICONS_TYPE_BOOK = 'svg-icons/type/book';
export const SVG_ICONS_TYPE_COMMENT = 'svg-icons/type/comment';
export const SVG_ICONS_TYPE_FACEBOOK = 'svg-icons/type/facebook';
export const SVG_ICONS_TYPE_GETPOCKET = 'svg-icons/type/getpocket';
export const SVG_ICONS_TYPE_LISTEN = 'svg-icons/type/listen';
export const SVG_ICONS_TYPE_LINKEDIN = 'svg-icons/type/linkedin';
export const SVG_ICONS_TYPE_MAIL = 'svg-icons/type/mail';
export const SVG_ICONS_TYPE_MESSENGER = 'svg-icons/type/messenger';
export const SVG_ICONS_TYPE_PINTEREST = 'svg-icons/type/pinterest';
export const SVG_ICONS_TYPE_PRINT = 'svg-icons/type/print';
export const SVG_ICONS_TYPE_SHARE = 'svg-icons/type/share';
export const SVG_ICONS_TYPE_TWITTER = 'svg-icons/type/twitter';
export const SVG_ICONS_TYPE_WHATSAPP = 'svg-icons/type/whatsapp';
export const SVG_ICONS_TYPE_XING = 'svg-icons/type/xing';
export const SVG_ICONS_TYPE_OFFLINE = 'svg-icons/type/offline';
export const SVG_ICONS_TYPE_CHEVRON_LEFT = 'svg-icons/type/chevron-left';
export const SVG_ICONS_TYPE_CHEVRON_RIGHT = 'svg-icons/type/chevron-right';
export const SVG_ICONS_TYPE_CART = 'svg-icons/type/cart';
export const SVG_ICONS_TYPE_CONTACT = 'svg-icons/type/contact';
export const SVG_ICONS_TYPE_CALCULATOR = 'svg-icons/type/calculator';
export const SVG_ICONS_TYPE_GOOGLE_NEWS = 'svg-icons/type/google-news';
export const SVG_ICONS_TYPE_INFO = 'svg-icons/type/info';
export const SVG_ICONS_TYPE_DOWNLOAD = 'svg-icons/type/download';
export const SVG_ICONS_TYPE_CLOSE = 'svg-icons/type/close';
export const SVG_ICONS_TYPE_SEARCH = 'svg-icons/type/search';
export const SVG_ICONS_TYPE_USER = 'svg-icons/type/user';
export const SVG_ICONS_TYPE_USER_ACTIVE = 'svg-icons/type/user-active';
export const SVG_ICONS_TYPE_MENU = 'svg-icons/type/menu';
export const SVG_ICONS_TYPE_UPLOAD = 'svg-icons/type/upload';
export const SVG_ICONS_TYPE_FULL_FILE_SPACE = 'svg-icons/type/full_file_space';
export const SVG_ICONS_TYPE_PHONE = 'svg-icons/type/phone';
export const SVG_ICONS_TYPE_INSTAGRAM = 'svg-icons/type/instagram';
export const SVG_ICONS_TYPE_SIGNAL = 'svg-icons/type/signal';
export const SVG_ICONS_TYPE_THREEMA = 'svg-icons/type/threema';
export const SVG_ICONS_TYPE_WEBSITE = 'svg-icons/type/website';
export const SVG_ICONS_TYPE_PLAY_BUTTON = 'svg-icons/type/play-button';
export const SVG_ICONS_TYPE_QUOTE = 'svg-icons/type/quote';
export const SVG_ICONS_TYPE_STAR_EMPTY = 'icons/type/star-empty';
export const SVG_ICONS_TYPE_STAR_FULL = 'icons/type/star-full';
export const SVG_ICONS_TYPE_FULLSCREEN = 'icons/type/fullscreen';
export const SVG_ICONS_TYPE_LIST = 'icons/type/list';
export const SVG_ICONS_TYPE_LIST_ACTIVE = 'icons/type/list-active';

export const SVG_ICONS_DEFAULT_CONFIG: Record<string, ReactElement> = {
  [SVG_ICONS_TYPE_ARROW_BACK]: ArrowBackIcon,
  [SVG_ICONS_TYPE_ARROW_UP_AND_DOWN]: ArrowUpAndDownIcon,
  [SVG_ICONS_TYPE_BOOK]: Book,
  [SVG_ICONS_TYPE_BOOKMARK_ACTIVE]: BookmarkActiveIcon,
  [SVG_ICONS_TYPE_BOOKMARK_INACTIVE]: BookmarkInactiveIcon,
  [SVG_ICONS_TYPE_CART]: Cart,
  [SVG_ICONS_TYPE_CHEVRON_LEFT]: ChevronLeft,
  [SVG_ICONS_TYPE_CHEVRON_RIGHT]: ChevronRight,
  [SVG_ICONS_TYPE_CLOSE]: Close,
  [SVG_ICONS_TYPE_SEARCH]: Search,
  [SVG_ICONS_TYPE_USER]: User,
  [SVG_ICONS_TYPE_MENU]: Menu,
  [SVG_ICONS_TYPE_USER_ACTIVE]: UserActive,
  [SVG_ICONS_TYPE_COMMENT]: CommentIcon,
  [SVG_ICONS_TYPE_FACEBOOK]: FacebookIcon,
  [SVG_ICONS_TYPE_GETPOCKET]: GetPocketIcon,
  [SVG_ICONS_TYPE_LISTEN]: ListenIcon,
  [SVG_ICONS_TYPE_LINKEDIN]: LinkedinIcon,
  [SVG_ICONS_TYPE_MAIL]: MailIcon,
  [SVG_ICONS_TYPE_MESSENGER]: MessengerIcon,
  [SVG_ICONS_TYPE_PINTEREST]: PinterestIcon,
  [SVG_ICONS_TYPE_PRINT]: PrintIcon,
  [SVG_ICONS_TYPE_SHARE]: ShareIcon,
  [SVG_ICONS_TYPE_TWITTER]: TwitterIcon,
  [SVG_ICONS_TYPE_WHATSAPP]: WhatsappIcon,
  [SVG_ICONS_TYPE_XING]: XingIcon,
  [SVG_ICONS_TYPE_OFFLINE]: OfflineIcon,
  [SVG_ICONS_TYPE_FULL_FILE_SPACE]: FullFileSpace,
  [SVG_ICONS_TYPE_UPLOAD]: Upload,
  [SVG_ICONS_TYPE_CONTACT]: Contact,
  [SVG_ICONS_TYPE_CALCULATOR]: Calculator,
  [SVG_ICONS_TYPE_DOWNLOAD]: Download,
  [SVG_ICONS_TYPE_INFO]: Info,
  [SVG_ICONS_TYPE_PHONE]: PhoneIcon,
  [SVG_ICONS_TYPE_INSTAGRAM]: InstagramIcon,
  [SVG_ICONS_TYPE_SIGNAL]: SignalIcon,
  [SVG_ICONS_TYPE_THREEMA]: ThreemaIcon,
  [SVG_ICONS_TYPE_WEBSITE]: WebsiteIcon,
  [SVG_ICONS_TYPE_FULLSCREEN]: FullscreenIcon,
  [SVG_ICONS_TYPE_PLAY_BUTTON]: PlayButtonIcon,
  [SVG_ICONS_TYPE_QUOTE]: QuoteIcon,
  [SVG_ICONS_TYPE_STAR_EMPTY]: StarEmptyIcon,
  [SVG_ICONS_TYPE_STAR_FULL]: StarFullIcon,
  [SVG_ICONS_TYPE_GOOGLE_NEWS]: GoogleNewsIcon,
  [SVG_ICONS_TYPE_LIST]: ListIcon,
  [SVG_ICONS_TYPE_LIST_ACTIVE]: ListIconActive,
};
