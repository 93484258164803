import { Component } from 'react';
import { connect } from 'react-redux';
import { EMAIL_ALERT_ANCHOR_ID } from '../AlertList/factory';
import {
  getServiceUrl,
  isLocalRunningAppConnectedToProdService,
  isPrInstance,
} from '../../../shared/helpers/serviceUrl';
import storageAvailable from '../../../shared/helpers/storage';
import { tealiumTrackEvent } from '../../../shared/helpers/tealium';
import { getCookieByName, log } from '../../../shared/helpers/utils';
import { setAuthData } from '../../../shared/actions/auth';
import { setPianoUserMetadata } from '../../../shared/actions/piano';
import { AD_FREE_SUBSCRIPTION_KEY } from '../../../shared/constants/ads';
import { COMMENTS_ANCHOR_ID } from '../../../shared/constants/comments';
import {
  AUTH0_APP_METADATA,
  AUTH0_LOCAL_STORAGE_ACCESS_TOKEN,
  AUTH0_LOCAL_STORAGE_ID_TOKEN,
  AUTH0_LOCAL_STORAGE_NONCE,
  AUTH0_LOCAL_STORAGE_STATE,
  AUTH0_USER_ID,
  AUTH0_USER_METADATA,
  RC_EMAIL,
  RC_SUB,
} from './constants';
import { auth0Config } from './config';
import { AuthorizeOptions, LoginCase } from './typings';

type Auth0PropsInner = {
  setPianoUserMetadata: any;
  setAuthData: any;
};

/**
 * Ensure that all old localStorage items are removed.
 * In an older implementation of the Auth0Provider we stored critical data e.g. access tokens in LS
 * We need to make sure this data is removed on every device.
 */
export const removeLocalStorageData = () => {
  if (storageAvailable('localStorage')) {
    localStorage.removeItem(AUTH0_LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(AUTH0_LOCAL_STORAGE_ID_TOKEN);
    localStorage.removeItem(AUTH0_LOCAL_STORAGE_NONCE);
    localStorage.removeItem(AUTH0_LOCAL_STORAGE_STATE);
    localStorage.removeItem(AUTH0_USER_ID);
    log(
      'AUTH0',
      `removed:
      ${AUTH0_LOCAL_STORAGE_ACCESS_TOKEN},
      ${AUTH0_LOCAL_STORAGE_ID_TOKEN},
      ${AUTH0_LOCAL_STORAGE_NONCE},
      ${AUTH0_LOCAL_STORAGE_STATE},
      ${AUTH0_USER_ID}
      items from localStorage
      `,
      'green',
    );
  }
};

export class Auth0 extends Component<Auth0PropsInner> {
  user: any;
  static _hasUsername = false;
  static _isAuthenticated = false;
  static _accessToken = '';
  static _userId = '';
  static _internalUserId = '';
  static tokenRenewalTimeout: NodeJS.Timeout;
  static _externalSubscription = '';
  static onInvite: (data) => void;

  /**
   * RIAD definitions can be found here: https://jira.ringieraxelspringer.ch/browse/RDP-1676
   *
   * case 1: user is not logged in but he was once logged in with a userid so we provide the userid from the localstorage
   *  value=1
   * case 2: user is logged in with auth0 so we provide the userid (sub) from auth0
   *  value=2
   * case 3: user is not logged in and he was never logged
   *  value=0
   *
   * same function is copied and used also in init-thirdparty files
   */
  getOlid = () => {
    const inactiveUserSub =
      storageAvailable('localStorage') && global.localStorage.getItem(RC_SUB);

    if (
      this.user?.sub ||
      global?.__INITIAL_ADS_CONFIG__?.config?.[AD_FREE_SUBSCRIPTION_KEY] ===
        false
    ) {
      return '2';
    }

    if (inactiveUserSub) {
      return '1';
    }
    return '0';
  };
  async componentDidMount() {
    try {
      log(
        'AUTH0',
        `componentDidMount auth0Config ${JSON.stringify(auth0Config, null, 2)}`,
        'green',
      );
      Auth0.onInvite = this._handleInvitedUser;
      await this._handleLogin();
      global.olid = this.getOlid();
    } catch (error) {
      log(
        'AUTH0',
        `componentDidMount auth0Config/instance ${JSON.stringify(
          error,
          null,
          2,
        )}`,
        'red',
      );
    }
  }

  static hasToken = () =>
    new Promise(async (resolve) => {
      try {
        const response = await fetch(`${Auth0.getAuthServiceUrl()}/userinfo`, {
          credentials: 'include',
        });

        const data = await response.json();

        if (data && (data.sub || data.internalUserId)) {
          resolve(true);
          return;
        }
        resolve(false);
        return;
      } catch (error) {
        resolve(false);
        return;
      }
    });

  static getAuthServiceUrl = () => getServiceUrl(__AUTH_SERVICE_URL__);

  static getAuthenticationUrl = () => getServiceUrl(__AUTH_SERVICE_URL__);

  static async invite(email: string) {
    log('AUTH0', 'invite', 'green');
    const appState: any = {};

    if (
      isPrInstance() ||
      isLocalRunningAppConnectedToProdService(__AUTH_SERVICE_URL__)
    ) {
      appState.isCookieCrossSiteAccessAllowed = true;
    }
    const appStateAsString = JSON.stringify(appState);
    const searchParams = new URLSearchParams({
      email,
      state: appStateAsString,
    });
    const invitationStatus = await fetch(
      `${Auth0.getAuthServiceUrl()}/invite?${searchParams}`,
      {
        credentials: 'include',
      },
    );

    if (!invitationStatus) {
      return null;
    }

    return invitationStatus.json();
  }

  static async login(
    loginCase: LoginCase = 'general',
    source?: string,
    redirectPath = '',
    emailHint?: string,
    getLoginUrl = false,
  ) {
    if (__SERVER__) {
      return;
    }
    log('AUTH0', 'login', 'green');
    let redirectUri =
      redirectPath || window.location.pathname + window.location.search;
    const hash = window.location.hash;

    // append existing hash to redirect to clicked e-mail alert
    if (hash.indexOf(EMAIL_ALERT_ANCHOR_ID)) {
      redirectUri = `${redirectUri}${hash}`;
    }

    if (loginCase === 'commenting') {
      // remove existing anchor to avoid duplicate anchors
      const anchorPosition = redirectUri.indexOf('#');
      if (anchorPosition >= 0) {
        redirectUri = redirectUri.substring(0, anchorPosition);
      }
      redirectUri = `${redirectUri}#${COMMENTS_ANCHOR_ID}`;
    }

    const appState: any = {
      redirectUri: window.location.origin + redirectUri,
      loginCase: loginCase,
      issuer: 'ONELOG',
    };

    if (
      isPrInstance() ||
      isLocalRunningAppConnectedToProdService(__AUTH_SERVICE_URL__)
    ) {
      appState.isCookieCrossSiteAccessAllowed = true;
    }

    const appStateAsString = JSON.stringify(appState);

    const getConsent = () => {
      const expectedConsent = ['C0001', 'C0002', 'C0003', 'C0005'];
      const activeGroups = __CLIENT__ && global.OnetrustActiveGroups;

      return expectedConsent.every(
        (consent) => activeGroups?.includes(consent),
      );
    };

    const loginOptions: AuthorizeOptions = {
      appState: appStateAsString,
      login_case: loginCase,
      source: source || '',
      ext_tracking_consent: getConsent(),
    };

    const gaClientId = getCookieByName('_ga');
    const gaSidValue = getCookieByName(`_ga_${__GA_SID__}`);

    const authorizeUrl = `https://${__AUTH0_DOMAIN__}/authorize`;

    const searchParams = new URLSearchParams({
      client_id: __AUTH0_CLIENT_ID__,
      response_type: auth0Config.response_type,
      redirect_uri: `${Auth0.getAuthServiceUrl()}/login/`,
      scope: auth0Config.scope,
      audience: auth0Config.audience,
      state: loginOptions.appState,
      login_case: loginOptions.login_case,
      ext_tracking_consent: loginOptions.ext_tracking_consent.toString(),
      ...(emailHint && { email_hint: emailHint }),
      google_login:
        (loginOptions.login_case === 'google_login' && 'true') || 'false',
    });

    if (loginOptions.source) {
      searchParams.append('source', loginOptions.source);
    }

    if (gaClientId) {
      searchParams.append('gacid', gaClientId);
    }

    if (gaSidValue) {
      searchParams.append('ga_sid', __GA_SID__);
      searchParams.append('ga_value', gaSidValue);
    }

    log('AUTH0.getLoginUrl', `return ${authorizeUrl}?${searchParams}`, 'green');

    if (__AUTH_LOGIN_OFFLINE_ENABLED__) {
      const fallbackUrl = '/login-offline';
      window.open(fallbackUrl, '_blank') || window.location.assign(fallbackUrl);
      return;
    }

    if (getLoginUrl) {
      // const url = `${authorizeUrl}?${searchParams}`;
      // console.log('xxx - url: ', url);
      return `${authorizeUrl}?${searchParams}`;
    } else {
      window.location.assign(`${authorizeUrl}?${searchParams}`);
    }
  }

  static async getLoginUrl(
    loginCase: LoginCase = 'general',
    source?: string,
    redirectPath = '',
    emailHint?: string,
  ) {
    return this.login(loginCase, source, redirectPath, emailHint, true);
  }

  static logout() {
    if (!__CLIENT__) {
      return;
    }

    clearTimeout(Auth0.tokenRenewalTimeout);
    Auth0._hasUsername = false;
    Auth0._isAuthenticated = false;
    removeLocalStorageData();

    const url = `https://${__AUTH0_DOMAIN__}/v2/logout?client_id=${__AUTH0_CLIENT_ID__}&returnTo=${Auth0.getAuthServiceUrl()}/logout?redirectUri=${
      window.location.href
    }`;
    window.location.assign(`${url}`);
  }

  static hasUsername() {
    return Auth0._hasUsername;
  }

  static isAuthenticated() {
    return Auth0._isAuthenticated;
  }

  static getUserId() {
    return Auth0._userId;
  }

  static getInternalUserId() {
    return Auth0._internalUserId;
  }

  static getExternalSubscription() {
    return Auth0._externalSubscription;
  }

  static setExternalSubscription(externalSubscription: string) {
    Auth0._externalSubscription =
      externalSubscription || Auth0._externalSubscription;
  }

  static getAccessToken() {
    return Auth0._accessToken || '';
  }

  _sendTealiumEvent = (cms_user_subscriptions = '') => {
    const userRegistrationStatus =
      this?.user?.internalUserId && !this.user.sub ? 'lean' : 'full';

    const userIdPayload = {
      event_name: 'set_user_id',
      event_category: 'user_id',
      event_action: 'set user_id',
      event_label: this.user.sub,
      event_non_interaction: '1',
      cms_user_id: this.user.sub,
      internal_user_id: this?.user?.internalUserId || '',
      cms_user_subscriptions,
      registration_status: userRegistrationStatus,
    };

    tealiumTrackEvent({
      type: 'link',
      payload: userIdPayload,
    });
  };

  _handleInvitedUser = (data) => {
    this.user = data;
    this.props.setPianoUserMetadata({
      idToken: data.id_token,
      initialAuthRequest: true,
    });
    this.props.setAuthData({
      email: this.user.email,
      isAuthenticated: !!this.user.email,
      initialAuthRequest: true,
    });
    Auth0._isAuthenticated = true;
    this._sendTealiumEvent();
    Auth0._internalUserId = this?.user?.internalUserId;
  };

  _handleLogin = async () => {
    log('AUTH0', '_handleLogin start', 'green');

    try {
      const response = await fetch(`${Auth0.getAuthServiceUrl()}/userinfo`, {
        credentials: 'include',
      });

      const data = await response.json();

      // always update rc:email in localstorage
      if (data?.email_encoded) {
        localStorage.setItem(RC_EMAIL, data.email_encoded);
      }

      if (!data || (!data.sub && !data.internalUserId)) {
        log('AUTH0', '_handleLogin failed', 'red');
        this._handleLogout();
        return;
      }
      if (data.access_token) {
        Auth0._accessToken = data.access_token;
      }
      if (data.sub || data.internalUserId) {
        this.user = data;
        log(
          'AUTH0',
          `_handleLogin user data: ${JSON.stringify(this.user, null, 2)}`,
          'green',
        );

        const externalSubscription = this._getExternalSubscription();

        this.props.setPianoUserMetadata({
          idToken: data.id_token,
          externalSubscription: externalSubscription,
          initialAuthRequest: true,
        });
        Auth0.setExternalSubscription(externalSubscription);

        const metadataSubscriptions = this._getMetadataValue(
          AUTH0_APP_METADATA,
          'subscriptions',
        );
        let subscriptions = [];
        let legalAdviceSubscriptions = [];

        if (Array.isArray(metadataSubscriptions)) {
          subscriptions = metadataSubscriptions.filter(
            (subscription) => !subscription.magazinId.startsWith('GU'),
          );
          legalAdviceSubscriptions = metadataSubscriptions.filter(
            (subscription) => subscription.magazinId.startsWith('GU'),
          );
        }

        const hasSubscriptions =
          Array.isArray(subscriptions) && subscriptions.length > 0;

        const hasLegalAdviceSubscriptions = legalAdviceSubscriptions.length > 0;

        this.props.setAuthData({
          username: this._getMetadataValue(AUTH0_USER_METADATA, 'username'),
          givenName: this._getMetadataValue(AUTH0_USER_METADATA, 'given_name'),
          familyName: this._getMetadataValue(
            AUTH0_USER_METADATA,
            'family_name',
          ),
          email: this.user.email,
          isAuthenticated: !!this.user.email,
          hasSubscriptions,
          registrationTimestamp: this._getMetadataValue(
            AUTH0_APP_METADATA,
            'registrationTimestamp',
          ),
          subscriptionTimestamp:
            (hasSubscriptions &&
              (subscriptions as Record<string, any>)[0]?.startDate) ||
            null,
          initialAuthRequest: true,
          subscriptions:
            (hasSubscriptions &&
              (subscriptions as Record<string, any>).map((item) => item.gid)) ||
            null,
          gpNumber: this._getMetadataValue(AUTH0_USER_METADATA, 'gpNumber'),
          address: this._getMetadataValue(AUTH0_USER_METADATA, 'address'),
          birthday: this._getMetadataValue(AUTH0_USER_METADATA, 'birthday'),
          mobileNumber: this._getMetadataValue(
            AUTH0_USER_METADATA,
            'mobileNumber',
          ),
          realtime: this._getMetadataValue(AUTH0_APP_METADATA, 'realtime'),
          hasLegalAdviceAccess: hasLegalAdviceSubscriptions,
          legalAdviceSubscriptions:
            (hasLegalAdviceSubscriptions &&
              (legalAdviceSubscriptions as Record<string, any>).map(
                (item) => item.magazinId,
              )) ||
            null,
        });

        // #adFree Hide Ad slots if user is logged in (client-side logic for the use case: right after login)
        if (this._getMetadataValue(AUTH0_APP_METADATA, 'adFree')) {
          document.body.insertAdjacentHTML(
            'beforeend',
            '<style>.ad-wrapper{display:none !important}</style>',
          );
        }

        Auth0._hasUsername =
          (this._getMetadataValue(AUTH0_USER_METADATA, 'username') && true) ||
          false;
        Auth0._isAuthenticated = true;

        let cms_user_subscriptions = '';

        if (Array.isArray(subscriptions) && subscriptions.length > 0) {
          subscriptions.forEach((subscription) => {
            if (
              subscription.magazinId &&
              subscription.gid &&
              subscription.source &&
              subscription.source !== 'FALLBACK'
            ) {
              cms_user_subscriptions += `${subscription.magazinId}:${subscription.gid}:${subscription.source};`;
            }
          });
        }

        if (Auth0._userId === '' && Auth0._internalUserId === '') {
          this._sendTealiumEvent(cms_user_subscriptions);
        }

        Auth0._userId = this.user.sub;
        Auth0._internalUserId = this?.user?.internalUserId || '';

        // Do not remove RC_SUB from localstorage on loggout, it is used by the riad we provide the last usersub so they can identify user also when he is not logged in
        localStorage.setItem(RC_SUB, this.user.sub);

        this._scheduleRenewal(data.refresh_in);
      }

      if (data?.['admeira:id']) {
        localStorage.setItem('adm:id', data['admeira:id'] || '');
      } else {
        localStorage.setItem('adm:id', '');
      }
    } catch (error) {
      this._handleLogout();
      log('AUTH0', `_handleLogin ${JSON.stringify(error, null, 2)}`, 'red');
    }
  };

  _handleLogout(): void {
    Auth0._hasUsername = false;
    Auth0._isAuthenticated = false;
    Auth0._userId = '';
    Auth0._internalUserId = '';
    Auth0._accessToken = '';
    localStorage.removeItem(AUTH0_USER_ID);

    this.props.setPianoUserMetadata({
      initialAuthRequest: true,
    });
    this.props.setAuthData({
      username: null,
      givenName: null,
      familyName: null,
      email: null,
      isAuthenticated: false,
      hasSubscriptions: false,
      initialAuthRequest: true,
      subscriptions: null,
      hasLegalAdviceAccess: false,
      legalAdviceSubscriptions: null,
    });
  }

  _scheduleRenewal(delay = 300000) {
    Auth0.tokenRenewalTimeout = setTimeout(() => {
      log('AUTH0', '_scheduleRenewal ran', 'orange');
      this._handleLogin();
    }, delay);
  }

  _getMetadataValue(
    metadataType: string,
    key?: string,
  ): { [key: string]: string } | string {
    if (this.user) {
      if (this.user[`https://www.ringieraxelspringer.ch/${metadataType}`]) {
        const metadata: { [key: string]: string } =
          this.user[`https://www.ringieraxelspringer.ch/${metadataType}`];

        if (key && metadata && metadata[key]) {
          return metadata[key];
        } else if (metadata && !key) {
          return metadata;
        }
      }
    }
    return '';
  }

  _getExternalSubscription(): string {
    const subscriptions: any = this._getMetadataValue(
      AUTH0_APP_METADATA,
      'subscriptions',
    );

    if (Array.isArray(subscriptions)) {
      const subscription: any = subscriptions.filter((subscription) => {
        const isPianoSource = subscription.source === 'PIANO';
        const isMetadataSource = subscription.source === 'METADATA';

        let pianoAdFreeResources = [];

        if (__PIANO_AD_FREE_RESOURCES__) {
          pianoAdFreeResources = __PIANO_AD_FREE_RESOURCES__.split(' ');
        }

        return (
          (!isMetadataSource && !isPianoSource) ||
          (isPianoSource && pianoAdFreeResources.includes(subscription.rid))
        );
      });

      return (subscription[0] && subscription[0].gid) || '';
    }

    return '';
  }

  render() {
    if (!global?.olid) {
      global.olid = this.getOlid();
    }
    return null;
  }
}

const mapDispatchToProps = {
  setPianoUserMetadata,
  setAuthData,
};

export default connect(null, mapDispatchToProps)(Auth0);
